import React, { useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  Space,
  Row,
  Col,
  Typography,
  Radio
} from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { get, post } from "../../api/Request";
import { ApiResWithUser, RepsFormat } from "../../types/General";
import { useAuth } from "../../context/AuthContext";
import { RepsSetsField } from "../../types/UserData";
import AddCustomSetRepField from "./AddCustomSetRepField";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/GlobalContext";
import { UserRole } from "../../types/Practitioner";

const { Option } = Select;
const { Title } = Typography;
interface DraggableInputItem {
  id: string;
  name: string;
  labelBefore: string;
  inputType: string;
  labelAfter: string;
  enabled: boolean;
}

interface SetsRepsFormProps {}

const PreviewField: React.FC<{ field: RepsSetsField }> = ({ field }) => {
  // For checkbox type, just show the label
  if (field.inputType === "checkbox") {
    return (
      <Typography.Text style={{ opacity: field.enabled ? 1 : 0.5 }}>
        {field.labelBefore}
      </Typography.Text>
    );
  }

  // For text/number inputs, show label: ___ format
  return (
    <Typography.Text style={{ opacity: field.enabled ? 1 : 0.5 }}>
      {field.labelBefore} [x]
      {field.labelAfter && ` ${field.labelAfter}`}
    </Typography.Text>
  );
};

const SetsRepsForm: React.FC<SetsRepsFormProps> = () => {
  const [form] = Form.useForm();
  const [items, setItems] = React.useState<RepsSetsField[]>([]);
  const [customItems, setCustomItems] = React.useState<RepsSetsField[]>([]);
  const [customRepsTemplate, setCustomRepsTemplate] = React.useState<
    RepsSetsField[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const { isMobile } = useGlobalContext();
  React.useEffect(() => {
    if (user?.settings.reps_template) {
      setItems(user?.settings.reps_template as RepsSetsField[]);
      setCustomItems(
        user?.settings.reps_template.filter(rt =>
          rt.id.includes("cef_")
        ) as RepsSetsField[]
      );
    }
  }, [user]);
  const defaultTemplate = [
    {
      id: "sets",
      name: t("plan.reps.fieldNames.sets"),
      labelBefore: t("plan.reps.sets") + ":",
      inputType: "number",
      labelAfter: "",
      enabled: true
    },
    {
      id: "repetitions",
      name: t("plan.reps.fieldNames.repetitions"),
      labelBefore: t("plan.reps.repeatitions"),
      inputType: "number",
      labelAfter: "",
      enabled: true
    },
    {
      id: "time",
      name: t("plan.reps.fieldNames.time"),
      labelBefore: t("plan.reps.time"),
      inputType: "text",
      labelAfter: "",
      enabled: true
    },
    {
      id: "rest",
      name: t("plan.reps.fieldNames.rest"),
      labelBefore: t("plan.reps.rest"),
      inputType: "text",
      labelAfter: "",
      enabled: true
    },
    {
      id: "days_per_week",
      name: t("plan.reps.daysPerWeek2"),
      labelBefore: "",
      inputType: "number",
      labelAfter: t("plan.reps.daysPerWeek"),
      enabled: true
    },
    {
      id: "both_sides",
      name: t("plan.reps.fieldNames.bothSides"),
      labelBefore: t("plan.reps.bothSides"),
      inputType: "checkbox",
      labelAfter: "",
      enabled: true
    }
  ];

  const customTemplate = [
    {
      id: "time",
      name: t("plan.reps.fieldNames.time"),
      labelBefore: t("plan.reps.holdFor"),
      inputType: "text",
      labelAfter: "",
      enabled: true
    },
    {
      id: "repetitions",
      name: t("plan.reps.fieldNames.repetitions"),
      labelBefore: t("plan.reps.repeat"),
      inputType: "number",
      labelAfter: t("plan.reps.times"),
      enabled: true
    },
    {
      id: "sets",
      name: t("plan.reps.fieldNames.sets"),
      labelBefore: t("plan.reps.perform"),
      inputType: "number",
      labelAfter: t("plan.reps.timesDaily"),
      enabled: true
    },
    {
      id: "rest",
      name: t("plan.reps.fieldNames.rest"),
      labelBefore: t("plan.reps.restFor"),
      inputType: "text",
      labelAfter: "",
      enabled: true
    },
    {
      id: "days_per_week",
      name: t("plan.reps.fieldNames.daysPerWeek"),
      labelBefore: t("plan.reps.perform"),
      inputType: "text",
      labelAfter: t("plan.reps.daysPerWeek"),
      enabled: true
    },
    {
      id: "both_sides",
      name: t("plan.reps.fieldNames.bothSides"),
      labelBefore: t("plan.reps.bothSides"),
      inputType: "checkbox",
      labelAfter: "",
      enabled: true
    }
  ];
  const grid = isMobile ? 4 : 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    border: "1px solid #eee",

    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#fefefe" : "#fff",
    borderRadius: 8,
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#fefefe" : "transparent",
    padding: grid,
    width: "100%",
    margin: "0"
  });
  const onDragEnd = result => {
    if (!result.destination) return;
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setItems(newItems);
  };

  const getCustomRepsTemplate = () => {
    get("account/getCustomRepsTemplate").then(r => {
      if (r.data.reps_template) {
        setCustomRepsTemplate(r.data.reps_template as RepsSetsField[]);
        console.log("custom reps template", r.data.reps_template);
      }
    });
  };
  useEffect(() => {
    getCustomRepsTemplate();
    if (user?.settings.reps_template) {
      setItems(user?.settings.reps_template as RepsSetsField[]);
      setCustomItems(
        user?.settings.reps_template.filter(rt =>
          rt.id.includes("cef_")
        ) as RepsSetsField[]
      );
    }
  }, [user]);

  const generateTemplateString = (template: RepsSetsField[]) => {
    return (
      template
        // .filter(item => item.enabled)
        .map((item, index, filteredArray) => (
          <React.Fragment key={item.id}>
            <PreviewField field={item} />
            {index !== filteredArray.length - 1 && (
              <Typography.Text>, </Typography.Text>
            )}
          </React.Fragment>
        ))
    );
  };

  const onFinish = (values: Record<string, any>) => {
    const transformedItems =
      values[`time.inputType`]?.length > 0
        ? items.map(item => ({
            ...item,
            labelBefore: values[`${item.id}.labelBefore`] || "",
            inputType: values[`${item.id}.inputType`] || "",
            labelAfter: values[`${item.id}.labelAfter`] || "",
            enabled: Boolean(values[`${item.id}.enabled`])
          }))
        : null;

    setLoading(true);
    console.log(transformedItems, "t items", values);
    post<ApiResWithUser>("account/saveRepsTemplate", {
      reps_template: transformedItems ? JSON.stringify(transformedItems) : null,
      reps_format: values.reps_format,
      applyToAllPractitioners: values.applyToAllPractitioners
    })
      .then(r => {
        // console.log("res data", r.data);
        if (r.data.user) {
          setUser(r.data.user);
          // console.log(user, "oiuoiuoi");
          setCustomRepsTemplate(transformedItems ? transformedItems : []);
        }

        // if (r.data.clinics.length > 1) navigate("/choose-clinic", {replace:true});
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (items.length > 0) {
      if (isEditing === true) {
        // setIsEditing(false);
        form.resetFields();
        // setIsEditing(true);
      } else {
        form.resetFields();
        // setIsEditing(true);
      }
    }
  }, [items, isEditing]);
  // Function to validate the entire form when an input changes
  const handleFieldChange = (itemID: string) => {
    form.validateFields([`${itemID}.labelBefore`, `${itemID}.labelAfter`]);
  };

  const handleTemplateSelect = template => {
    // Reset form first
    setIsEditing(false);
    // form.resetFields();

    // Set new template items
    setItems(template);
    // setCustomRepsTemplate(template);

    // // Update form with new values
    // const formValues = template.reduce((acc, item) => {
    //   acc[item.id] = {
    //     labelBefore: item.labelBefore,
    //     labelAfter: item.labelAfter,
    //     enabled: item.enabled
    //   };
    //   return acc;
    // }, {});

    // form.setFieldsValue(formValues);
    setIsEditing(true);
  };

  const frequencyOptions = [
    {
      key: 1,
      label: (
        <>
          {generateTemplateString(defaultTemplate.concat(customItems))}{" "}
          <Button
            type="dashed"
            size="small"
            onClick={() => {
              handleTemplateSelect(defaultTemplate.concat(customItems));
              form.setFieldValue("reps_format", RepsFormat.default);
            }}
          >
            {t("edit")}
          </Button>
        </>
      ),
      value: RepsFormat.default
    },
    {
      key: 2,
      label: (
        <>
          {generateTemplateString(customTemplate.concat(customItems))}{" "}
          <Button
            type="dashed"
            size="small"
            onClick={() => {
              handleTemplateSelect(customTemplate.concat(customItems));
              // form.setFieldValue("reps_format", RepsFormat.custom);
            }}
          >
            {t("edit")}
          </Button>
        </>
      ),
      value: RepsFormat.custom
    },
    customRepsTemplate.length > 0 && {
      key: 3,
      label: (
        <>
          {t("settings.repsFormat.myTemplate")}
          {": "}
          {generateTemplateString(customRepsTemplate)}{" "}
          <Button
            type="dashed"
            size="small"
            onClick={() => {
              handleTemplateSelect(customRepsTemplate);
              // form.setFieldValue("reps_format", RepsFormat.myTemplate);
            }}
          >
            {t("edit")}
          </Button>
        </>
      ),
      value: RepsFormat.myTemplate
    }
  ].filter(Boolean) as { key: number; label: JSX.Element; value: RepsFormat }[]; // Filter out false values and assert type

  return (
    <>
      <br />
      <AddCustomSetRepField
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onDone={() => {
          setModalVisible(false);
          // Refresh the fields list if needed
        }}
      />{" "}
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="reps_format" initialValue={user?.settings.reps_format}>
          <Radio.Group
            options={frequencyOptions}
            optionType="default"
            // onChange={e => {
            //   if (e.target.value !== RepsFormat.myTemplate) {
            //     setIsEditing(false);
            //   }
            // }}
          />
        </Form.Item>

        <Space.Compact style={{ marginTop: 8 }} block>
          <Button type="primary" htmlType="submit" loading={loading} block>
            {t("save")}
          </Button>
          <Button
            hidden={!isEditing}
            type="default"
            onClick={() => setIsEditing(!isEditing)}
            block
          >
            {isEditing ? t("close") : t("edit")}
          </Button>
          <Button type="default" onClick={() => setModalVisible(true)} block>
            {t("plan.reps.addCustomField")}
          </Button>
          {/* <Dropdown
            
            menu={{ items: templateItems }}
            placement="bottomRight"
          >
            <Button block>
              {t("plan.reps.templates")} <DownOutlined />
            </Button>
          </Dropdown> */}
        </Space.Compact>

        {isEditing && (
          <>
            {" "}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="setsRepsForm">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={index + item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <h3
                              style={
                                item.id.startsWith("cef_")
                                  ? { color: "blue" }
                                  : {}
                              }
                            >
                              {item.name}{" "}
                              {item.id.startsWith("cef_") ? "*" : ""}
                            </h3>
                            <Row gutter={[8, 8]}>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item
                                  label={
                                    item.inputType === "checkbox"
                                      ? t("plan.reps.label")
                                      : t("plan.reps.labelBefore")
                                  }
                                  name={`${item.id}.labelBefore`}
                                  initialValue={item.labelBefore}
                                  style={{ margin: 0 }}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          getFieldValue(`${item.id}.enabled`) &&
                                          !value &&
                                          !getFieldValue(
                                            `${item.id}.labelAfter`
                                          )
                                        ) {
                                          return Promise.reject(
                                            new Error(
                                              "Either Label Before or Label After is required when enabled."
                                            )
                                          );
                                        }
                                        return Promise.resolve();
                                      }
                                    })
                                  ]}
                                >
                                  <Input
                                    onChange={() => handleFieldChange(item.id)}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                                <Form.Item
                                  label="Input"
                                  name={`${item.id}.inputType`}
                                  initialValue={item.inputType}
                                  style={{ margin: 0 }}
                                >
                                  <Select
                                    onChange={() => handleFieldChange(item.id)}
                                    disabled={item.inputType === "checkbox"}
                                  >
                                    <Option value="text">
                                      {t("plan.reps.text")}
                                    </Option>
                                    <Option value="number">
                                      {t("plan.reps.number")}
                                    </Option>

                                    {item.inputType === "checkbox" && (
                                      <Option value="checkbox">
                                        {t("plan.reps.checkbox")}
                                      </Option>
                                    )}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col xs={8} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item
                                  hidden={item.inputType === "checkbox"}
                                  label={t("plan.reps.labelAfter")}
                                  name={`${item.id}.labelAfter`}
                                  initialValue={item.labelAfter}
                                  style={{ margin: 0 }}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          getFieldValue(
                                            `${item.id}.inputType`
                                          ) === "checkbox" &&
                                          value
                                        ) {
                                          return Promise.reject(
                                            new Error(
                                              "Label After is not allowed for Checkbox input type."
                                            )
                                          );
                                        }
                                        return Promise.resolve();
                                      }
                                    })
                                  ]}
                                >
                                  <Input
                                    // disabled={
                                    //   form.getFieldValue(`${item.id}.inputType`) ===
                                    //   "checkbox"
                                    // }
                                    onChange={() => handleFieldChange(item.id)}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} sm={8} md={3} lg={3} xl={3}>
                                <Form.Item
                                  label="Enabled"
                                  name={`${item.id}.enabled`}
                                  valuePropName="checked"
                                  initialValue={item.enabled}
                                  style={{ margin: 0 }}
                                >
                                  <Checkbox
                                    onChange={() => handleFieldChange(item.id)}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} sm={4} md={1} lg={1} xl={1}>
                                <div
                                  {...provided.dragHandleProps}
                                  style={{ paddingTop: 32 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faGripVertical}
                                    style={{ cursor: "grab" }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {user?.practitioner.role !== UserRole.practitioner && (
                <Form.Item
                  name="applyToAllPractitioners"
                  valuePropName="checked"
                  style={{ margin: "8px" }}
                >
                  <Checkbox>{t("plan.reps.saveForAll")}</Checkbox>
                </Form.Item>
              )}
              <Form.Item style={{ margin: "0 8px", display: "block" }}>
                <Space.Compact block>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    {t("save")}
                  </Button>
                </Space.Compact>
              </Form.Item>
            </DragDropContext>
          </>
        )}
      </Form>
    </>
  );
};
export default SetsRepsForm;
