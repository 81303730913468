import React, { useEffect, useState } from "react";
import { Form, Button, Checkbox, Radio, Tooltip, Space } from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { ApiResWithUser, RepsFormat } from "../../types/General";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MyDivider from "../../components/helper/MyDivider";
import { config } from "../../data/Constants";
import SetsRepsForm from "./SetsRepsForm";

interface AuthFormDTO {
  email: string;
  password: string;
}
const EditLetterhead: React.FC = () => {
  const navigate = useNavigate();

  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const frequencyOptions = [
    {
      key: 1,
      label: t("settings.repsFormat.default"),
      value: RepsFormat.default
    },
    {
      key: 2,
      label: t("settings.repsFormat.custom"),
      value: RepsFormat.custom
    }
  ];
  const fontOptions = [
    {
      key: 1,
      label: "9pt",
      value: 9
    },
    {
      key: 2,
      label: "10pt",
      value: 10
    },
    {
      key: 3,
      label: "11pt",
      value: 11
    },
    {
      key: 4,
      label: "12pt",
      value: 12
    }
  ];

  const notesOptions = [
    { key: 1, label: t("settings.letterhead.notesBottom"), value: 0 },
    { key: 2, label: t("settings.letterhead.notesTop"), value: 1 }
  ];
  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post<ApiResWithUser>("account/upsertSettings", values)
      .then(r => {
        // console.log("res data", r.data);
        if (r.data.user) {
          setUser(r.data.user);
          // console.log(user, "oiuoiuoi");
        }
        // if (r.data.clinics.length > 1) navigate("/choose-clinic", {replace:true});
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log(user);
  }, [user, navigate]);

  return (
    <>
      <MyDivider
        title={t("settings.letterhead.editFrequency")}
        tooltip={t("settings.letterhead.editFrequencyHint")}
      />
      <SetsRepsForm />

      <Form
        style={{ marginTop: 16 }}
        name="basic"
        layout="vertical"
        className="segmented"
        initialValues={{
          reps_format: user?.settings.reps_format,
          ex_desc_font_size: user?.settings.ex_desc_font_size,
          additional_notes_on_top: user?.settings.additional_notes_on_top,
          add_practitioner_on_plan: user?.settings.add_practitioner_on_plan,
          hide_videos: user?.settings.hide_videos,
          hide_date: user?.settings.hide_date
        }}
        onFinish={onFinish}
        autoComplete="on"
      >
        {/* <Form.Item
          // className="big-title"
          // label={t("settings.letterhead.editFrequency")}
          // tooltip={t("settings.letterhead.editFrequencyHint")}
          name="reps_format"
        >
          <Radio.Group options={frequencyOptions} optionType="default" />
        </Form.Item> */}
        <MyDivider
          title={t("settings.letterhead.fontSize")}
          tooltip={t("settings.letterhead.fontSizeHint")}
        />
        <Form.Item className="big-title" name="ex_desc_font_size">
          <Radio.Group
            options={fontOptions}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <MyDivider
          title={t("settings.letterhead.notesPosition")}
          // tooltip={t("settings.letterhead.fontSizeHint")}
        />
        <Form.Item name="additional_notes_on_top">
          <Radio.Group
            options={notesOptions}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <MyDivider title={t("settings.other")} />
        <Form.Item name="add_practitioner_on_plan" valuePropName="checked">
          <Checkbox>
            {t("settings.letterhead.practitionerOnPlan")}{" "}
            <Tooltip title={t("settings.letterhead.practitionerOnPlanHint")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <Form.Item name="hide_videos" valuePropName="checked">
          <Checkbox>
            {t("settings.letterhead.hideVideo")}{" "}
            <Tooltip title={t("settings.letterhead.hideVideoHint")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <Form.Item name="hide_date" valuePropName="checked">
          <Checkbox>
            {t("settings.letterhead.hideDate")}{" "}
            <Tooltip title={t("settings.letterhead.hideDateHint")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Space.Compact block>
            <Button loading={loading} type="primary" htmlType="submit" block>
              {t("save")}
            </Button>
            <Button
              onClick={() =>
                window.open(config.API_URL + "plan/testLetterhead", "_blank")
              }
              block
            >
              {t("settings.letterhead.testLetterhead")}
            </Button>
          </Space.Compact>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditLetterhead;
