import { Table, Tooltip, Typography, Tag, Skeleton } from "antd";
import { ColumnsType } from "antd/lib/table";
import { WarningOutlined, CheckCircleOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../api/Request";
import "react-quill/dist/quill.snow.css";
import { History, MessageStatus } from "../../types/Patient";
import { formatDateTime } from "../../helpers/dateHelper";
import { useAuth } from "../../context/AuthContext";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  getMessageStatusColor,
  getPractitionerName
} from "../../helpers/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import MyModal from "../../components/MyModal";
const { Text } = Typography;

const WADeliveryStatus: React.FC<{ status: any }> = ({ status }) => {
  if (!status) return null;

  const { delivery_info } = status;

  const getStatusColor = (status: string) => {
    switch (status) {
      case "delivered":
        return "success";
      case "read":
        return "success";
      case "undelivered":
        return "error";
      default:
        return "warning";
    }
  };

  return (
    <div style={{ marginTop: 4 }}>
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <Tooltip
          title={
            getStatusColor(delivery_info.status) != "success"
              ? delivery_info.error_description
              : ""
          }
        >
          <Tag color={getStatusColor(delivery_info.status)}>
            {delivery_info.is_delivered ? (
              <CheckCircleOutlined style={{ marginRight: 4 }} />
            ) : (
              <WarningOutlined style={{ marginRight: 4 }} />
            )}
            {delivery_info.status.toUpperCase()}
          </Tag>
        </Tooltip>

        <Text type="secondary">
          {/* {formatDateTime(delivery_info.date_sent, "en")} */}
        </Text>
      </div>

      {/* {delivery_info.has_error && (
        <Tooltip title={delivery_info.error_description}>
          <Text
            type="danger"
            style={{ fontSize: "0.9em", display: "block", marginTop: 4 }}
          >
            {delivery_info.failure_reason || delivery_info.error_description}
          </Text>
        </Tooltip>
      )} */}

      {/* <div style={{ fontSize: "0.85em", marginTop: 4 }}>
        <Text type="secondary">
          To: {delivery_info.to?.replace("whatsapp:", "")}
        </Text>
      </div> */}
    </div>
  );
};

interface Props {
  patientId: number;
  showModal: boolean;
  onCancel: () => void;
}

const PatientHistoryModal: React.FC<Props> = ({
  patientId,
  showModal,
  onCancel
}) => {
  const { t } = useTranslation();

  const { user } = useAuth();
  const { language } = useGlobalContext();

  const [history, setHistory] = useState<History[]>();

  useEffect(() => {
    showModal &&
      get<History[]>("patient/history/" + patientId)
        .then(r => {
          setHistory(r.data);
          // console.log(r.data, "history data");
        })
        .catch(_ => {});
  }, [patientId, showModal]);

  const columns: ColumnsType<History> = [
    {
      title: t("action"),
      key: "name",
      sorter: false,
      render: (text, record) => (
        <div>
          <Text type="success">
            {t("patientHistory." + record.action, {
              object: record.object ?? " -- "
            })}
          </Text>
          <br />
          <Text type="secondary">
            {getPractitionerName(record.practitioner_id, user)}
          </Text>
          {record.wa_delivery_status && (
            <WADeliveryStatus status={record.wa_delivery_status} />
          )}
          {record.delivery_log && record.delivery_log.length > 0 && (
            <>
              {record.delivery_log.map(dl => (
                <div style={{ marginTop: 4 }} key={dl.id}>
                  <Tooltip title={dl.reason}>
                    <Tag color={getMessageStatusColor(dl.status)}>
                      {dl.status === MessageStatus.MESSAGE_STATUS_DELIVERED ||
                      dl.status === MessageStatus.MESSAGE_STATUS_OPENED ? (
                        <CheckCircleOutlined style={{ marginRight: 4 }} />
                      ) : (
                        <WarningOutlined style={{ marginRight: 4 }} />
                      )}
                      {t("messageStatus.ms" + dl.status)}{" "}
                    </Tag>
                  </Tooltip>

                  <Text type="secondary">
                    {/* {formatDateTime(delivery_info.date_sent, "en")} */}
                  </Text>
                  {/* <FontAwesomeIcon
                    icon={faCircle}
                    color={getMessageStatusColor(dl.status)}
                  />{" "}
                  {t("messageStatus.ms" + dl.status)}{" "} */}
                  {/* <Text type="secondary">
                    {formatDateTime(dl.date, language)}
                  </Text> */}
                </div>
              ))}
            </>
          )}
        </div>
      )
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      className: "pointer",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: date => (
        <span className="tsec_col">{formatDateTime(date, language)}</span>
      )
    }
  ];

  return (
    <MyModal
      title={t("patient.patientHistory")}
      open={showModal}
      onCancel={onCancel}
      destroyOnClose
      okText={t("ok")}
      onOk={() => {
        onCancel();
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText={t("close")}
    >
      <>
        {history === undefined ? (
          <Skeleton active />
        ) : (
          <Table
            className="patient-history-table"
            pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
            dataSource={history}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                // onClick: event => {
                //   navigate("/patient/plan/" + record.id);
                // },
                onDoubleClick: event => {}, // double click row
                onContextMenu: event => {}, // right button click row
                onMouseEnter: event => {}, // mouse enter row
                onMouseLeave: event => {} // mouse leave row
              };
            }}
          />
        )}
      </>
    </MyModal>
  );
};

export default PatientHistoryModal;
