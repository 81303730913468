import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  Alert,
  Form,
  Radio,
  Switch,
  Typography,
  Space,
  Skeleton
} from "antd";
import { useTranslation } from "react-i18next";
import { Licence } from "../../types/UserData";

import { get, post } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { useAuth } from "../../context/AuthContext";
import { PricePlan } from "../../types/Payment";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "../../data/Constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingColumns,
  faCreditCard,
  faTag
} from "@fortawesome/free-solid-svg-icons";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { useGlobalContext } from "../../context/GlobalContext";
import { Language } from "../../types/General";

const { Text, Link, Title } = Typography;
const Payment: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user, setUser, refreshSession } = useAuth();
  const [params] = useSearchParams();
  const { isMobile } = useGlobalContext();
  const payment: string = (params.get("payment") as string) ?? ""; // stripe done
  const bacs: string = (params.get("bacs") as string) ?? ""; // stripe done + bacs payment
  const token: string = (params.get("token") as string) ?? ""; // paypal done
  const canceled: string = (params.get("cancel") as string) ?? ""; // paypal canceled

  const [plan, setPlan] = useState<PricePlan>();
  const [couponInput, setCouponInput] = useState("");
  const [paymentMethod, sePaymentMethod] = useState("stripe");
  const [paymentPeriod, setPaymentPeriod] = useState("month");
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [minClinics, setMinClinics] = useState(2);
  const [clinicNumber, setClinicNumber] = useState(
    user && user.clinics_allowed > 1 ? user.clinics_allowed : 2
  );

  const isPlAccount = user
    ? user.lang === Language.plPl
      ? true
      : false
    : false;

  const getPricePlan = () => {
    post("account/pricePlan/", { coupon: coupon })
      .then(r => {
        // console.log("done pricing", r.data);
        setPlan(r.data);
      })
      .catch(_ => {});
  };

  const selectLicence = (licence: Licence) => {
    post("account/selectLicence", { licence: licence, clinics: clinicNumber })
      .then(r => {
        setUser(r.data.user);

        // navigate("/patients", { replace: true });
      })
      .catch(_ => {});
  };

  const paypalPayment = (period: "month" | "year") => {
    setLoading(true);
    post("payment/paypalRecurringSessionCreate/", { period: period })
      .then(r => {
        // console.log("done pp", r.data);

        window.open(r.data, "_blank");
      })
      .catch(_ => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const stripePayment = (period: string) => {
    setLoading(true);

    post("payment/stripeRecurringSessionCreate/", {
      period: period,
      bacs: paymentMethod === "bacs"
    })
      .then(r => {
        // console.log("done stripe", r.data);
        const stripePromise = loadStripe(
          isPlAccount ? config.STRIPE_KEY_PL : config.STRIPE_KEY
        );
        stripePromise.then(s => {
          s?.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as argument here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: r.data.id
          }).then(function (result) {
            console.log(result, "stripe then");
            // get("payment/stripeCheckPayment/" + r.data.id, {
            //   paymentMethod
            // }).then(r => {
            //   setUser(r.data.user);
            //   navigate("/patients?ref=stripe+payment_completed", {
            //     replace: true
            //   });
            // });
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        });
      })
      .catch(_ => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setClinicNumber(
      user && user.clinics_allowed > 1 ? user.clinics_allowed : 2
    );
  }, [user]);

  useEffect(() => {
    // if (user && user.practitioner.role === UserRole.practitioner)
    // n  avigate("/patients", { replace: true });
    getPricePlan();
    if (user && user.licence === Licence.multiClinic)
      setMinClinics(
        user.current_clinics.length > 1 ? user.current_clinics.length : 2
      );
  }, [user, coupon]);

  useEffect(() => {
    if (token !== "" && user && canceled === "") {
      setLoading2(true);
      get("payment/payPalStep2/", { token: token })
        .then(r => {
          setUser(r.data.user);
          navigate("/patients?ref=pp_payment_completed", { replace: true });
        })
        .catch(_ => {})
        .finally(() => {
          setLoading2(false);
        });
    }
  }, [token, user]);

  useEffect(() => {
    if (payment !== "" && user) {
      get("payment/stripeCheckPayment/" + payment, { bacs })
        .then(r => {
          setUser(r.data.user);
          navigate("/patients?ref=stripe_payment_completed", { replace: true });
        })
        .catch(_ => {});
    }
  }, [payment, user]);

  useEffect(() => {
    refreshSession();
  }, []);

  useEffect(() => {
    if (
      user &&
      clinicNumber >= minClinics &&
      user.clinics_allowed !== clinicNumber &&
      user.licence === Licence.multiClinic
    )
      selectLicence(Licence.multiClinic);
  }, [clinicNumber]);

  function goToPayment(): void {
    if (paymentMethod === "stripe" || paymentMethod === "bacs") {
      stripePayment(paymentPeriod);
    } else {
      paypalPayment(paymentPeriod as "month" | "year");
    }
    // throw new Error("Function not implemented.");
  }

  if (loading2) return <Skeleton active />;
  return (
    <>
      <MyPageHeader title={t("payment.title")}></MyPageHeader>
      <Row gutter={[16, 16]}>
        <Col span={24} order={2} lg={{ span: 8, order: 1 }}>
          <div className="box" style={{ textAlign: "center" }}>
            {user?.payment_required && (
              <Alert
                message={t("payment.licenceExpired")}
                // description="Error Description Error Description Error Description Error Description Error Description Error Description"
                type="error"
                closable
                style={{ marginBottom: 16 }}
              />
            )}
            <h1> {t("account.licences.l" + user?.licence)}</h1>
            {user?.licence !== Licence.individual &&
              t("account.upToPractitioners", {
                p: user && user.allowed_practitioners_num
              })}
            {user?.licence === Licence.individual &&
              t("account.onlyOnePractitioner")}
            {user?.licence === Licence.multiClinic && (
              <Form.Item
                className="margin-top"
                style={{ textAlign: "center" }}
                label={
                  t("account.numberOfClinics") +
                  " (" +
                  minClinics.toString() +
                  "+)"
                }
              >
                <Input
                  type="number"
                  min={minClinics}
                  value={clinicNumber}
                  onChange={e => {
                    setClinicNumber(parseInt(e.target.value));
                  }}
                />
              </Form.Item>
            )}
            <h3 style={{ marginTop: 32 }}>{t("account.changeLicence")}</h3>
            <Button
              block
              type="primary"
              className="margin-bottom-8"
              hidden={user?.licence === Licence.clinic}
              onClick={() => {
                selectLicence(Licence.clinic);
              }}
            >
              {t("account.switchTo") +
                " " +
                t("account.licences.l" + Licence.clinic)}
            </Button>
            <Button
              className="margin-bottom-8"
              type="primary"
              block
              hidden={user?.licence === Licence.multiClinic}
              onClick={() => {
                selectLicence(Licence.multiClinic);
              }}
            >
              {t("account.switchTo") +
                " " +
                t("account.licences.l" + Licence.multiClinic)}
            </Button>
            <Button
              block
              type="primary"
              hidden={user?.licence === Licence.individual}
              onClick={() => {
                selectLicence(Licence.individual);
              }}
            >
              {t("account.switchTo") +
                " " +
                t("account.licences.l" + Licence.individual)}
            </Button>
          </div>
        </Col>
        <Col span={24} order={1} lg={{ span: 16, order: 2 }}>
          {plan && (
            <div className="box">
              <Title level={3} style={{ textAlign: "center" }}>
                {t("payment.purchaseLicence")}
              </Title>

              <Radio.Group
                onChange={e => {
                  sePaymentMethod(e.target.value);
                }}
                defaultValue={paymentMethod}
                optionType="default"
                buttonStyle="solid"
                style={{ width: "100%" }}
              >
                <Radio
                  className={
                    paymentMethod === "stripe" ? "selected-payment" : ""
                  }
                  value="stripe"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                    className="payment-method"
                  >
                    <span style={{ flex: 1 }}>
                      {t("payment.buyUsingCreditCard")}
                    </span>

                    {!isMobile && (
                      <img
                        src="/assets/img/cc.png"
                        style={{ maxHeight: 25 }}
                        alt="pay using card"
                      />
                    )}
                  </span>

                  <Text type="secondary">
                    {isMobile && (
                      <img
                        src="/assets/img/cc.png"
                        style={{
                          maxHeight: 20,
                          display: "block",
                          margin: "8px 0"
                        }}
                        alt="pay using card"
                      />
                    )}
                    {t("payment.buyUsingCreditCardHint")}
                  </Text>
                </Radio>
                <Radio
                  className={paymentMethod === "bacs" ? "selected-payment" : ""}
                  value="bacs"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                    className="payment-method"
                  >
                    <span style={{ flex: 1 }}>{t("payment.buyUsingBacs")}</span>
                  </span>

                  <Text type="secondary">{t("payment.buyUsingBacsHint")}</Text>
                </Radio>

                {!isPlAccount && (
                  <Radio
                    className={
                      paymentMethod === "paypal" ? "selected-payment" : ""
                    }
                    value="paypal"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}
                      className="payment-method"
                    >
                      <span style={{ flex: 1 }}>PayPal</span>
                      <img
                        src="/assets/img/pp.png"
                        style={{ maxHeight: 25 }}
                        alt="pay using paypal"
                      />
                    </span>

                    <Text type="secondary">{t("payment.buyUsingPPHint")}</Text>
                  </Radio>
                )}
              </Radio.Group>
              <div className="price-container">
                <div className="payment-switcher">
                  <span>{t("payment.monthly")}</span>
                  <Switch
                    className="custom-switch"
                    checked={paymentPeriod === "year"}
                    onChange={e => {
                      setPaymentPeriod(e ? "year" : "month");
                    }}
                  />
                  <span>{t("payment.yearly")}</span>
                </div>
                {paymentPeriod === "month" && (
                  <div style={{ textAlign: "center", marginTop: 8 }}>
                    {t("payment.switchToYearlySave")}
                    <b>
                      {plan.y.discount}% ({plan.m.currency_print}
                      {plan.y.you_save}){" "}
                    </b>
                  </div>
                )}
                <div className="price-year">
                  <span className="price">
                    {paymentPeriod === "month" ? (
                      <>
                        <span className="currency">
                          {plan.m.currency_print}
                        </span>
                        <span className="new-price">
                          {plan.m.regular_price}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="old-price">
                          {plan.m.currency_print}
                          {plan.y.regular_price}
                        </span>
                        <br />
                        <span className="new-price">
                          <span className="currency">
                            {plan.m.currency_print}
                          </span>
                          {plan.y.price}
                        </span>
                      </>
                    )}
                  </span>
                  <div>
                    {paymentPeriod === "month" && t("payment.perMonth")}
                    {paymentPeriod === "year" && plan.discount.y === 10 && (
                      <>
                        {t("payment.perYear")} <br />
                        <Button size="small">
                          {plan.y.discount + "% OFF!"}
                        </Button>
                      </>
                    )}
                    {paymentPeriod === "year" &&
                    plan.y.price !== plan.y.recurring_price ? (
                      <>
                        {t("payment.forFirstYear")} <br />
                        <Button size="small">
                          {plan.y.discount + "% OFF!"}
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {paymentPeriod === "year" &&
                      plan.y.price !== plan.y.recurring_price && (
                        <>
                          {t("payment.recurringPrice")} {plan.m.currency_print}
                          {plan.y.recurring_price}
                          <br />
                          <Button size="small">10% OFF!</Button>
                        </>
                      )}
                  </div>
                </div>

                {!showCouponForm && (
                  <Text style={{ marginBottom: 16 }}>
                    {t("payment.haveCoupon")}{" "}
                    <Link onClick={() => setShowCouponForm(true)}>
                      {t("clickHere")}
                    </Link>
                  </Text>
                )}

                <Space.Compact
                  size="large"
                  // block
                  direction={isMobile ? "vertical" : "horizontal"}
                  style={{ marginBottom: 16 }}
                  hidden={!showCouponForm}
                >
                  <Input
                    prefix={
                      <FontAwesomeIcon
                        icon={faTag}
                        style={coupon.length > 0 ? { color: "green" } : {}}
                      />
                    }
                    value={couponInput}
                    onChange={e => {
                      setCoupon("");
                      setCouponInput(e.target.value);
                    }}
                  />
                  <Button
                    hidden={coupon.length > 0}
                    color="green"
                    variant="solid"
                    onClick={_ => {
                      post("payment/isValidCoupon/", {
                        coupon: couponInput
                      })
                        .then(r => {
                          setCoupon(couponInput);
                          setPaymentPeriod("year");
                          // console.log("done pricing", r.data);
                        })
                        .catch(_ => {
                          setCoupon("");
                        });
                    }}
                  >
                    {t("apply")}
                  </Button>
                  <Button
                    type="default"
                    color="red"
                    variant="filled"
                    onClick={_ => {
                      setCoupon("");
                      setCouponInput("");
                    }}
                    hidden={coupon.length === 0}
                  >
                    {t("remove")}
                  </Button>
                </Space.Compact>
              </div>

              <Button
                block
                size="large"
                style={isMobile ? { fontSize: 13 } : {}}
                loading={loading}
                color="green"
                variant="solid"
                className="payment-btn"
                onClick={_ => goToPayment()}
              >
                {paymentMethod === "stripe" && (
                  <>
                    <FontAwesomeIcon icon={faCreditCard} />{" "}
                    {t("payment.payNow")}
                  </>
                )}
                {paymentMethod === "paypal" && (
                  <>
                    {t("payment.continueTo")}{" "}
                    <FontAwesomeIcon icon={faPaypal} />
                    PayPal
                  </>
                )}
                {paymentMethod === "bacs" && (
                  <>
                    <FontAwesomeIcon icon={faBuildingColumns} />{" "}
                    {t("payment.payWithBacs")}
                  </>
                )}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Payment;
