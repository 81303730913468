import {
  Input,
  Select,
  Form,
  Checkbox,
  Result,
  Button,
  Typography
} from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import { get, post } from "../../api/Request";
import { PractitionerUpsert, UserRole } from "../../types/Practitioner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import MyDivider from "../../components/helper/MyDivider";
import { Licence } from "../../types/UserData";
import MyModal from "../../components/MyModal";
const { Text } = Typography;

interface Props {
  practitioner: PractitionerUpsert;
  showModal: boolean;
  onDone: () => void;
  onCancel: () => void;
}

interface ExistingPractitioner {
  first_name: string;
  last_name: string;
  email: string;
  id: number;
}

interface ClinikoKey {
  id: number;
  name: string;
  cliniko_api_key: string;
}

const UpsertPractitionerModal: React.FC<Props> = ({
  practitioner,
  showModal,
  onCancel,
  onDone
}) => {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();

  const [form] = Form.useForm();

  const [hideClinicsSelect, setHideClinicsSelect] = useState(true);
  const [formData, setFormData] = useState(practitioner);
  const [clinikoKeys, setClinikoKeys] = useState<ClinikoKey[]>([]);
  const [existingPract, setExistingPract] = useState<ExistingPractitioner>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(practitioner, "p upsert");
    form.setFieldsValue(practitioner);
    setFormData(practitioner);
    setExistingPract(undefined);
  }, [practitioner]);

  useEffect(() => {
    if (!showModal) {
      setClinikoKeys([]); // Reset clinikoKeys when modal closes
    } else {
      get("practitioner/getClinikoApiKeys/" + practitioner.id).then(r => {
        setClinikoKeys(r.data);
      });
    }
  }, [showModal, practitioner.id]);

  useEffect(() => {
    if (existingPract != undefined)
      form.setFieldsValue({ ...form.getFieldsValue(), ...existingPract });
  }, [existingPract]);

  useEffect(() => {
    if (user) {
      if (user.clinics.length === 1) return setHideClinicsSelect(true);
    }
    if (practitioner.access_to_all_clinics) setHideClinicsSelect(true);
    else setHideClinicsSelect(false);
  }, [practitioner, user]);

  const submit = v => {
    setLoading(true);

    post("practitioner/upsert", v)
      .then(r => {
        setUser(r.data.user);
        onDone();
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  const checkExisting = email => {
    if (practitioner.id > 0) return;
    post("practitioner/checkExisting", { email: email, id: practitioner.id })
      .then(r => {
        if (r.data.id !== undefined) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            email: r.data.email
          });
          setExistingPract(r.data);
        } else setExistingPract(undefined);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <MyModal
      title={practitioner.id > 0 ? t("pract.edit") : t("pract.add")}
      open={showModal}
      onCancel={onCancel}
      okText={t("save")}
      // destroyOnClose
      okButtonProps={{ loading: loading }}
      cancelText={t("cancel")}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            submit(values);
          })
          .catch(info => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      {practitioner && user && form && (
        <Form
          preserve={false}
          form={form}
          id="smsForm"
          layout="vertical"
          // autoComplete="off"
          // initialValues={{
          //   ...practitioner,
          //   clinics: practitioner.clinics.map(c => {
          //     return c.toString;
          //   })
          // }}
          onValuesChange={k => {
            setFormData(form.getFieldsValue());
          }}
        >
          {existingPract !== undefined && (
            <>
              <Form.Item hidden name="email">
                <Input />
              </Form.Item>
              <Result
                icon={<FontAwesomeIcon icon={faUser} size="4x" />}
                title={existingPract.first_name + " " + existingPract.last_name}
                subTitle={existingPract.email}
                extra={
                  <>
                    <Text type="secondary">{t("pract.exists")}</Text>
                    <br />
                    <Button
                      type="primary"
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          first_name: "",
                          last_name: "",
                          email: "",
                          id: 0
                        });
                        setExistingPract(undefined);
                      }}
                    >
                      {t("pract.removePractitioner")}
                    </Button>
                  </>
                }
              />
            </>
          )}
          {practitioner.id > 0 && (
            <>
              <Form.Item hidden name="id">
                <Input required />
              </Form.Item>
              <Form.Item
                label={t("email")}
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email"
                  }
                ]}
              >
                <Input disabled={practitioner.id > 0} />
              </Form.Item>
              <Form.Item
                label={t("firstName")}
                name="first_name"
                rules={[
                  {
                    required: true
                    //   message: t("form.fieldRequired", { field: t("firstName") })
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("lastName")}
                name="last_name"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Result
                  icon={<></>}
                  title={practitioner.first_name + " " + practitioner.last_name}
                  subTitle={practitioner.email}
                /> */}
            </>
          )}
          {existingPract === undefined && practitioner.id === 0 && (
            <>
              <Form.Item hidden name="id">
                <Input required />
              </Form.Item>
              <Form.Item
                label={t("email")}
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email"
                  }
                ]}
              >
                <Input
                  onBlur={e => {
                    // console.log("onblur", );
                    checkExisting(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("firstName")}
                name="first_name"
                rules={[
                  {
                    required: true
                    //   message: t("form.fieldRequired", { field: t("firstName") })
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("lastName")}
                name="last_name"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item
            label={t("pract.roleTitle")}
            name="role"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              style={{ width: "100%" }}
              // onChange={handleChange}
              disabled={practitioner.role === UserRole.owner}
              options={[
                {
                  value: UserRole.practitioner,
                  label: t("pract.role.rl" + UserRole.practitioner)
                },
                {
                  value: UserRole.admin,
                  label: t("pract.role.rl" + UserRole.admin)
                }
              ]}
            />
          </Form.Item>

          {(user.current_clinics.length > 1 ||
            user.licence === Licence.multiClinic) && (
            <>
              <MyDivider title={t("pract.allowAccessToClinics")} />

              <Form.Item
                name="access_to_all_clinics"
                // label={t("pract.allowAccessToClinics")}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={e => {
                    setHideClinicsSelect(e.target.checked);
                  }}
                >
                  {t("pract.allowAccessToAllClinics")}
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="clinics"
                hidden={hideClinicsSelect}
                // valuePropName="clinics"
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  // defaultValue={practitioner.clinics}

                  options={user.clinics
                    .filter(d => d.member_id === user.id)
                    .map(c => {
                      return {
                        label: c.clinic_name,
                        value: c.id
                      };
                    })}
                />
              </Form.Item>
            </>
          )}

          {practitioner.id > 0 && (
            <>
              <MyDivider title={t("auth.authentication")} />
              <Form.Item label={t("auth.newPassword")} name="new_password">
                <Input />
              </Form.Item>
            </>
          )}
          {practitioner.id === 0 && existingPract === undefined && (
            <>
              <MyDivider title={t("auth.authentication")} />

              <Form.Item
                label={t("auth.password")}
                name="password"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={
                  formData.new_password === "" || formData.password === ""
                }
                name="require_password_change"
                valuePropName="checked"
              >
                <Checkbox>{t("pract.requirePasswordChange")}</Checkbox>
              </Form.Item>
              <Form.Item
                hidden={
                  formData.new_password === "" || formData.password === ""
                }
                name="sldve"
                valuePropName="checked"
              >
                <Checkbox>{t("pract.sldve")}</Checkbox>
              </Form.Item>
            </>
          )}
          <MyDivider title={t("other")} />
          {clinikoKeys.map(clinic => (
            <>
              <Form.Item
                key={clinic.id}
                label={`Cliniko API key for ${clinic.name}`}
                name={`cliniko_api_key.${clinic.id}`} // Include clinic ID in the key
                initialValue={clinic.cliniko_api_key}
              >
                <Input placeholder={`Enter API key for ${clinic.name}`} />
              </Form.Item>
            </>
          ))}

          <Form.Item name="can_view_other_patients" valuePropName="checked">
            <Checkbox>{t("pract.canViewOtherPatients")}</Checkbox>
          </Form.Item>

          <Form.Item name="share_templates" valuePropName="checked">
            <Checkbox>{t("pract.shareTemplates")}</Checkbox>
          </Form.Item>
        </Form>
      )}
    </MyModal>
  );
};

export default UpsertPractitionerModal;
