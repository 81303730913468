import React, { useEffect, useState } from "react";
import { get, post } from "../../api/Request";
import MyPageHeader from "../../components/layout/MyPageHeader";
import { Navigate, useParams } from "react-router-dom";
import { PlanViewDTO } from "../../types/Plan";
import { Button, Tabs, Segmented, Menu, Dropdown, Skeleton, Space } from "antd";
import { useTranslation } from "react-i18next";
import CustomEmailModal from "./CustomEmailModal";
import ConfirmButton from "../../components/helper/ConfirmButton";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faEnvelope,
  faEnvelopeCircleCheck,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/AuthContext";
import SmsModal from "./SmsModal";
import { Patient2 } from "../../types/Patient";
import WhatsappModal from "./WhatsappModal";
import DownloadPlanModal from "./DownloadPlanModal";
import PlanExercisesView from "./PlanExercisesView";
import { getPlanEditLink } from "../../helpers/helper";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import PlanHeaderView from "./PlanHeaderView";
import PlanMonitorResult from "./PlanMonitorResult";
import PlanTrackingResult from "./PlanTrackingResult";
import PlanPromResult from "./PlanPromResult";
import { formatDate } from "../../helpers/dateHelper";
import { useGlobalContext } from "../../context/GlobalContext";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { Language, LanguageNames } from "../../types/General";
import { config } from "../../data/Constants";
import LangPrint from "../../components/helper/LangPrint";
const languageOptions = Object.entries(LanguageNames);

const PlanView: React.FC = () => {
  const { t } = useTranslation();
  const { language, isTabletOrMobile } = useGlobalContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const [emailPlanLoading, setEmailPlanLoading] = useState(false);
  const [patient, setPatient] = useState<Patient2>();
  const [redirectToProfile, setRedirectToProfile] = useState(false);
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showWhatsappModal, setShowWhatsappModal] = useState(false);
  const [translationLoading, setTranslationLoading] = useState(false);

  const [planData, setPlanData] = useState<PlanViewDTO>();
  const [tab, setTab] = useState("view");
  const [segmentedOptions, setSegmentedOptions] = useState<
    SegmentedLabeledOption[]
  >([]);
  const { user } = useAuth();

  const [isRtl, setIsRtl] = useState(false);
  useEffect(() => {
    if (planData) setIsRtl(config.RTL_LANGS.includes(planData.plan.lang));
  }, [planData]);

  const getPlan = () => {
    get<PlanViewDTO>("plan/patientPlan/" + id)
      .then(r => {
        setPlanData(r.data);
      })
      .catch(e => {});
  };

  const getPatient = (patient_id: number) => {
    get<Patient2>("patient/info/" + patient_id)
      .then(r => {
        // console.log(r.data);
        setPatient(r.data);
      })
      .catch(_ => {});
  };

  const emailPlan = () => {
    setEmailPlanLoading(true);
    get("send/emailPlan/" + id)
      .catch(_ => {})
      .finally(() => setEmailPlanLoading(false));
  };

  useEffect(() => {
    if (patient && user)
      if (patient.clinic_id !== user.clinic.id) navigate("/patients/");
    // get<UserObject>("account/changeClinic/" + patient.clinic_id)
    //   .then(r => {
    //     setUser(r.data);
    //   })
    //   .catch(_ => {});
  }, [user, patient]);

  useEffect(() => {
    if (parseInt(id ?? "")) getPlan();
  }, [id]);

  useEffect(() => {
    if (planData) {
      let options = [
        {
          label: t("plan.title"),
          value: "view"
        },

        {
          label: t("plan.tracking"),
          value: "tracking"
        }
      ];

      if (planData.prom.length > 0)
        options = [
          ...options,
          {
            label: `${t("plan.prom")} (${planData.prom_results})`,

            value: "prom"
          }
        ];
      if (planData.monitor.length > 0)
        options = [
          ...options,
          {
            label: t("plan.monitor"),
            value: "monitor"
          }
        ];

      setSegmentedOptions(options);
    }
  }, [planData]);

  useEffect(() => {
    if (planData?.plan.patient_id) getPatient(planData.plan.patient_id);
  }, [planData?.plan.patient_id]);

  if (redirectToProfile) {
    return <Navigate to={"/patient/" + planData?.plan.patient_id} />;
  }

  return (
    <>
      <MyPageHeader
        title={
          planData?.plan.name ? (
            <>
              {planData?.plan.name} <LangPrint lang={planData?.plan.lang} />
            </>
          ) : (
            t("plan.noName")
          )
        }
        subTitle={
          planData?.plan.has_tracking
            ? formatDate(planData.plan.start ?? "", language) +
              " - " +
              formatDate(planData.plan.end ?? "", language)
            : formatDate(planData?.plan.created ?? "", language)
        }
        extra={
          planData &&
          planData.plan && (
            <Space.Compact>
              <Button
                key="011"
                color="primary"
                variant="solid"
                onClick={_ => {
                  navigate(getPlanEditLink(planData.plan));
                }}
              >
                <FontAwesomeIcon icon={faEdit} /> {t("edit")}
              </Button>
              <Button
                key="0"
                color="primary"
                variant="solid"
                onClick={() => emailPlan()}
                loading={emailPlanLoading}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                {t("plan.email")}
              </Button>

              <Button
                key="1"
                color="primary"
                variant="solid"
                onClick={() => {
                  setShowCustomEmailModal(true);
                }}
              >
                <FontAwesomeIcon icon={faEnvelopeCircleCheck} />

                {t("plan.customEmail")}
              </Button>
              <Button
                key="2"
                color="primary"
                variant="solid"
                onClick={() => {
                  setShowSmsModal(true);
                }}
                hidden={!user?.settings.display_whatsapp_sms_button}
              >
                <FontAwesomeIcon icon={faCommentDots} />

                {t("sms.title")}
              </Button>

              <Button
                key="3"
                color="primary"
                variant="solid"
                onClick={() => {
                  setShowWhatsappModal(true);
                }}
                hidden={!user?.settings.display_whatsapp_sms_button}
              >
                <FontAwesomeIcon icon={faWhatsapp} />

                {t("plan.whatsapp")}
              </Button>
              <Button
                key="22"
                color="primary"
                variant="solid"
                onClick={() => {
                  setShowDownloadModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPrint} />

                {t("plan.printDownload")}
              </Button>
              <Dropdown
                // color="primary"
                // variant="solid" // loading={translationLoading}
                // style={{ float: "right" }}
                // size="large"

                placement="bottomRight"
                overlay={
                  <Menu
                    onClick={e => {
                      // if current plan ignore
                      if (planData.plan.lang === (e.key as Language)) return;

                      const translatedPlan = planData.plan.translations.find(
                        item => item.lang === e.key
                      )?.id;
                      if (translatedPlan) {
                        navigate("/patient/plan/" + translatedPlan);
                        return;
                      } else {
                        setTranslationLoading(true);

                        post("plan/translate", { id: id, lang: e.key })
                          .then(r => {
                            navigate("/patient/plan/" + r.data.plan_id);
                            return;
                          })
                          .catch(e => {})
                          .finally(() => setTranslationLoading(false));
                      }
                      // e.stopPropagation();
                    }}
                  >
                    {languageOptions.map(([langCode, langName]) => (
                      <Menu.Item key={langCode}>
                        {}
                        {langName}{" "}
                        {planData.plan.lang === langCode ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : (
                          ""
                        )}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button
                  size="large"
                  color="primary"
                  variant="solid"
                  type="primary"
                  loading={translationLoading}
                  onClick={e => e.stopPropagation()}
                >
                  {t("translate")}
                </Button>
              </Dropdown>
              <ConfirmButton
                key="4"
                btnText={t("delete")}
                message={t("confirmations.deletePlan")}
                btnProps={{ color: "red", variant: "filled", ghost: true }}
                onConfirm={() => {
                  get("plan/delete/" + id)
                    .then(() => {
                      setRedirectToProfile(true);
                    })
                    .catch(e => {});
                }}
              />
            </Space.Compact>
          )
        }
      ></MyPageHeader>

      {planData && patient && (
        <>
          {isTabletOrMobile && planData.plan.has_tracking && (
            <Segmented
              options={segmentedOptions}
              onChange={e => setTab(e.toString())}
              block
              defaultValue={tab}
              size="large"
            />
          )}
          <Tabs
            centered={true}
            className={
              planData.plan.has_tracking
                ? "patient-profile-tabs plan-tabs"
                : "patient-profile-tabs hide-tabs"
            }
            activeKey={tab}
            onChange={e => setTab(e.toString())}
            items={[
              {
                label: t("plan.title"),
                key: "view",
                children: planData && (
                  <div
                    className="box"
                    dir={isRtl ? "rtl" : "ltr"}
                    style={{ maxWidth: 1000, margin: "0 auto" }}
                  >
                    <PlanHeaderView
                      patientName={
                        patient?.first_name + " " + patient?.last_name
                      }
                      plan={planData.plan}
                      lang={planData.plan.lang}
                    />
                    {translationLoading ? (
                      <Skeleton active />
                    ) : (
                      <PlanExercisesView
                        exercises={planData.exercises}
                        notes={planData.plan.notes}
                        imageType={planData.plan.image_type}
                        lang={planData.plan.lang}
                      />
                    )}
                  </div>
                )
              },
              {
                label: t("plan.tracking"),
                key: "tracking",
                // disabled: !planData.plan.has_tracking,
                children: (
                  <div
                    className="box"
                    style={{ maxWidth: 1000, margin: "0 auto" }}
                  >
                    <PlanTrackingResult plan={planData} patient={patient} />
                  </div>
                )
              },
              {
                label: t("plan.monitor"),
                key: "monitor",
                disabled: planData.monitor.length === 0,
                children: (
                  <div
                    className="box"
                    style={{ maxWidth: 1000, margin: "0 auto" }}
                  >
                    <PlanMonitorResult plan={planData} patient={patient} />
                  </div>
                )
              },
              {
                label: `${t("plan.prom")} (${planData.prom_results})`,
                key: "prom",
                disabled: planData.prom.length === 0,
                children: (
                  <div
                    className="box"
                    style={{ maxWidth: 1000, margin: "0 auto" }}
                  >
                    <PlanPromResult plan={planData} patient={patient} />
                  </div>
                )
              }
            ]}
          ></Tabs>
        </>
      )}
      {showCustomEmailModal && (
        <CustomEmailModal
          planId={parseInt(id ?? "0")}
          planName={planData?.plan.name}
          showModal={showCustomEmailModal}
          onCancel={() => {
            setShowCustomEmailModal(false);
          }}
          onDone={() => {
            setShowCustomEmailModal(false);
          }}
        />
      )}

      <DownloadPlanModal
        planId={parseInt(id ?? "0")}
        showModal={showDownloadModal}
        token={planData?.plan.token ?? ""}
        onCancel={() => {
          setShowDownloadModal(false);
        }}
        onDone={() => {
          setShowDownloadModal(false);
        }}
      />
      <SmsModal
        planId={parseInt(id ?? "0")}
        patientId={planData?.plan.patient_id ?? 0}
        showModal={showSmsModal}
        onCancel={() => {
          setShowSmsModal(false);
        }}
        onDone={() => {
          setShowSmsModal(false);
        }}
      />
      <WhatsappModal
        planId={parseInt(id ?? "0")}
        patientId={planData?.plan.patient_id ?? 0}
        showModal={showWhatsappModal}
        onCancel={() => {
          setShowWhatsappModal(false);
        }}
        onDone={() => {
          setShowWhatsappModal(false);
        }}
      />
    </>
  );
};

export default PlanView;
