import React, { useEffect, useState } from "react";
import { Button, Col, Menu, Row } from "antd";
import type { MenuProps } from "antd";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalContext } from "../../context/GlobalContext";
import Column from "../../components/layout/Col";
import MyPageHeader from "../../components/layout/MyPageHeader";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import EditLetterhead from "./EditLetterhead";
import EmailOptions from "./EmailOptions";
import Payments from "./Payments";
import BuyCredit from "../payment/BuyCredit";
import CustomExerciseDescriptions from "./CustomExerciseDescriptions";
import MyAccount from "./MyAccount";
import UpsertClinic from "./UpsertClinic";
import Practitioners from "./Practitioners";
import {
  faChartBar,
  faChild,
  faCommentSms,
  faExchange,
  faHouseUser,
  faKey,
  faLeftRight,
  faLock,
  faNotesMedical,
  faPeopleArrows,
  faPlus,
  faRightFromBracket,
  faServer,
  faSms,
  faUsersGear
} from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "../../types/Practitioner";
import LetterheadSettings from "./LetterheadSettings";
import WhatsappSmsOptions from "./WhatsappSmsOptions";
import Api from "./Api";
import EmailSettings from "./EmailSettings";
import {
  faBuilding,
  faCreditCard,
  faEnvelope,
  faEnvelopeOpen,
  faFileExcel,
  faFileLines,
  faPenToSquare,
  faUser
} from "@fortawesome/free-regular-svg-icons";
import Integrations from "./integrations/Integrations";
import Cliniko from "./integrations/Cliniko";
import { Licence } from "../../types/UserData";
import IntegrationApiKey from "./IntegrationApiKey";
import Plato from "./integrations/Plato";
import Nookal from "./integrations/Nookal";
import Contact from "../Contact";
import TextContent from "../TextContent";
import MergeDuplicatePatients from "./MergeDuplicatePatients";
import Webhook from "./integrations/Webhook";
import SIF from "./integrations/SIF";
import ImportExport from "./ImportExport";
import ChooseClinicSelect from "../../components/helper/ChooseClinicSelect";
import MFA from "./MFA";
import StatsGlobal from "./StatsGlobal";
import Stats from "../account/Stats";
import ManageMyClinics from "./ManageMyClinics";
import ClinicalNoteTemplates from "./ClinicalNoteTemplates";

type MenuItem = Required<MenuProps>["items"][number];

interface SPage {
  title: string;
  component: any;
  url: string;
  icon?: any;
  limitToRoles?: UserRole[];
  hidden?: boolean;
}

const Settings: React.FC = () => {
  const { user, setUser, logout, refreshSession } = useAuth();
  const { page } = useParams();

  const [currentMenu, setCurrentMenu] = useState("index");
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [save, setSave] = useState(0);
  const [title, setTitle] = useState("Settings");
  const { t } = useTranslation();
  const { isTabletOrMobile } = useGlobalContext();
  const handleClick = e => {
    // console.log("click ", e);
    setCurrentMenu(e.key);
  };

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
    hidden?: boolean
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      style: hidden ? { display: "none" } : undefined
    } as MenuItem;
  }

  const pages: SPage[] = [
    {
      title: t("settings.myAccount"),
      component: <MyAccount />,
      url: "my-account",
      icon: faHouseUser,
      hidden: false
    },
    {
      title: t("settings.editProfile"),
      component: <EditProfile />,
      url: "edit-profile",
      icon: faUser,
      hidden: false
    },
    {
      title: t("settings.mfa"),
      component: <MFA />,
      url: "mfa",
      icon: faLock,
      hidden: false
    },
    {
      title: t("settings.integrationApiKey", {
        i: user?.clinic.integration_enabled.toLocaleUpperCase()
      }),
      component: <IntegrationApiKey />,
      url: "integration-api-key",
      icon: faExchange,
      hidden: !["cliniko", "nookal", "platomedical"].includes(
        user?.clinic.integration_enabled ?? ""
      )
    },
    {
      title: t("settings.changePassword"),
      component: <ChangePassword save={save} />,
      url: "change-password",
      icon: faKey,
      hidden: false
    },
    {
      title: t("settings.editLetterhead"),
      component: <EditLetterhead />,
      url: "edit-letterhead",
      icon: faFileLines,
      hidden: false
    },
    {
      title: t("settings.customExerciseDescriptions"),
      component: <CustomExerciseDescriptions />,
      url: "custom-exercise-descriptions",
      icon: faChild,
      hidden: false
    },
    {
      title: t("cn.templates"),
      component: <ClinicalNoteTemplates />,
      url: "clinical-note-templates",
      icon: faNotesMedical,
      hidden: false
    },
    {
      title: t("settings.emailOptions"),
      component: <EmailOptions />,
      url: "email-options",
      icon: faEnvelopeOpen,
      hidden: false
    },

    {
      title: t("settings.editClinic"),
      component: <UpsertClinic newClinic={false} />,
      url: "edit-clinic",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faPenToSquare,
      hidden: false
    },
    // {
    //   title: t("settings.createPersonalAccount"),
    //   component: <CreatePersonalAccount newClinic={true} />,
    //   url: "create-personal-account",
    //   // limitToRoles: [UserRole.admin, UserRole.practitioner],
    //   icon: faPenToSquare,
    //   hidden: false
    // },

    {
      title: t("settings.addNewClinic"),
      component: <UpsertClinic newClinic={true} />,
      url: "add-clinic",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faPlus,
      hidden: user?.licence !== Licence.multiClinic
    },
    {
      title: t("settings.manageClinics.title"),
      component: <ManageMyClinics />,
      url: "manage-clinics",
      limitToRoles: [UserRole.owner],
      icon: faBuilding,
      hidden: user?.licence !== Licence.multiClinic
    },
    {
      title: t("practitioners"),
      component: (
        <Practitioners
          showUpsertModal={showUpsertModal}
          setShowUpsertModal={setShowUpsertModal}
        />
      ),
      url: "practitioners",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faUsersGear,
      hidden: false
    },
    {
      title: t("settings.mergeDuplicatePatients"),
      component: <MergeDuplicatePatients />,
      url: "merge-patients",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faPeopleArrows,
      hidden: false
    },
    {
      title: t("settings.letterheadSettings"),
      component: <LetterheadSettings />,
      url: "letterhead-settings",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faFileLines,
      hidden: false
    },
    {
      title: t("settings.whatsappSmsOptions"),
      component: <WhatsappSmsOptions />,
      url: "whatsapp-sms-options",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faSms,
      hidden: false
    },
    {
      title: t("settings.emailSettings"),
      component: <EmailSettings />,
      url: "email-settings",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faEnvelope,
      hidden: false
    },

    {
      title: t("cliniko.title"),
      component: <Cliniko />,
      url: "integrations-cliniko",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: user?.clinic.integration_enabled !== "cliniko"
    },
    {
      title: "PPS",
      component: <TextContent id={528} />,
      url: "integrations-pps",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: true
    },
    {
      title: "Physiodiary",
      component: <TextContent id={529} />,
      url: "integrations-physiodiary",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: true
    },
    {
      title: "Plato Medical",
      component: <Plato />,
      url: "integrations-plato",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: user?.clinic.integration_enabled !== "platomedical"
    },
    {
      title: "Nookal",
      component: <Nookal />,
      url: "integrations-nookal",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,

      hidden: user?.clinic.integration_enabled !== "nookal"
    },

    {
      title: "SIF",
      component: <SIF />,
      url: "integrations-sif",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: true
    },
    {
      title: "Zapier",
      component: <TextContent id={537} />,
      url: "integrations-zapier",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: true
    },
    {
      title: "My Osteopathy",
      component: <TextContent id={538} />,
      url: "integrations-myosteopathy",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: true
    },
    {
      title: t("integrations.title"),
      component: <Integrations />,
      url: "integrations",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faLeftRight,
      hidden: false
    },
    {
      title: "Webhook",
      component: <Webhook />,
      url: "integrations-webhook",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faServer
      // hidden: true
    },
    {
      title: t("settings.api"),
      component: <Api />,
      url: "api",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faServer,
      hidden: false
    },
    {
      title: t("settings.importExport.title"),
      component: <ImportExport />,
      url: "import-export",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faFileExcel,
      hidden: false
    },
    {
      title: t("payments.title"),
      component: <Payments />,
      url: "payments",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faCreditCard,
      hidden: false
    },
    {
      title: t("payments.buyCredit"),
      component: <BuyCredit />,
      url: "buy-credit",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faCommentSms,
      hidden: false
    },
    {
      title: t("menu.contact"),
      component: <Contact onlyForm={true} />,
      url: "contact",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faEnvelope,
      hidden: false
    },
    {
      title: t("menu.stats"),
      component: <StatsGlobal />,
      url: "stats",
      limitToRoles: [UserRole.admin, UserRole.owner],
      icon: faChartBar,
      hidden: false
    }
  ];

  const profileMenuItems: MenuItem[] = pages
    .filter(p => p.limitToRoles === undefined)
    .map(p =>
      getItem(
        <Link to={`/settings/${p.url}`}>{p.title}</Link>,
        p.url,
        p.icon && <FontAwesomeIcon icon={p.icon} style={{ marginRight: 8 }} />,
        undefined,
        undefined,
        p.hidden
      )
    );

  const adminMenuItems: MenuItem[] = pages
    .filter(
      p =>
        p.limitToRoles !== undefined &&
        p.limitToRoles.includes(
          user ? user.practitioner.role : UserRole.practitioner
        )
    )
    .map(p =>
      getItem(
        <Link to={`/settings/${p.url}`}>{p.title}</Link>,
        p.url,
        p.icon && <FontAwesomeIcon icon={p.icon} style={{ marginRight: 8 }} />,
        undefined,
        undefined,
        p.hidden
      )
    );

  useEffect(() => {
    const current = pages.find(p => p.url === page);
    if (current) setTitle(current.title);

    setCurrentMenu(page ?? "index");
    // console.log(page, "page");
  }, [page]);

  useEffect(() => {
    if (save > 0) setSave(0);
  }, [save]);

  useEffect(() => {
    refreshSession();
  }, []);

  const renderSwitch = menu => {
    const page = pages.find(p => p.url === menu);
    if (page) return page.component;
  };
  return (
    <>
      <Column size="standard" style={{ marginTop: -50 }}>
        <MyPageHeader
          key="ph"
          title={title}
          backUrl="/settings"
          extra={[
            currentMenu === "practitioners" && (
              <Button
                key="anp"
                color="green"
                variant="solid"
                onClick={() => {
                  setShowUpsertModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />

                {t("pract.add")}
              </Button>
            )
            // <Button
            //   key="save"
            //   className="phb"
            //   onClick={() => {
            //     setSave(new Date().getMilliseconds());
            //   }}
            // >
            //   {t("save")}
            // </Button>
          ]}
          mobileRight={
            <FontAwesomeIcon
              icon={faRightFromBracket}
              onClick={() => logout()}
            />
          }
        />
        <Row
          className="wrapper123"
          gutter={!isTabletOrMobile ? [16, 16] : undefined}
        >
          {(!isTabletOrMobile || currentMenu === "index") && user && (
            <Col span={currentMenu === "index" ? 24 : 6}>
              <div className="box">
                {user.clinics.length > 1 && isTabletOrMobile && (
                  <>
                    <h4 className="blue-color">{t("account.chooseClinic")}</h4>
                    <ChooseClinicSelect
                      size="large"
                      style={{ width: "100%", marginBottom: 8 }}
                    />
                  </>
                )}
                <h4 className="blue-color">{t("account.myProfile")}</h4>
                <Menu
                  onClick={handleClick}
                  selectedKeys={[currentMenu]}
                  items={profileMenuItems}
                />

                {[UserRole.admin, UserRole.owner].includes(
                  user.practitioner.role
                ) && (
                  <>
                    <h4 className="blue-color" style={{ marginTop: 32 }}>
                      {t("account.administration")}
                    </h4>
                    <Menu items={adminMenuItems} />
                  </>
                )}
              </div>
            </Col>
          )}
          <Col
            hidden={currentMenu === "index"}
            span={!isTabletOrMobile ? 18 : 24}
          >
            <div className="box settings-page">{renderSwitch(currentMenu)}</div>
          </Col>
        </Row>
      </Column>
    </>
  );
};

export default Settings;
