import "./App.less";
import AlertProvider from "./context/AlertContext";
import { message, Alert, Button, ConfigProvider, Spin } from "antd";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import GlobalProvider, { useGlobalContext } from "./context/GlobalContext";
import { AuthProvider } from "./context/AuthContext";
import AppContent from "./AppContent";
import { useServiceWorker } from "./useSw";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  const { t } = useTranslation();

  const { languageAntd } = useGlobalContext();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [showSwUpdate, setShowSwUpdate] = useState(false);
  const [showSwLoading, setShowSwLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("errorMsg")) {
      message.error(urlParams.get("errorMsg"), 6);
    }
    if (urlParams.has("successMsg")) {
      message.success(urlParams.get("successMsg"), 6);
    }
    if (urlParams.has("warningMsg")) {
      message.success(urlParams.get("warningMsg"), 6);
    }
    if (urlParams.has("infoMsg")) {
      message.info(urlParams.get("infoMsg"), 6);
    }
  }, []);

  useEffect(() => {
    // console.log(waitingWorker, showReload, "SW FILES");
    if (showReload && waitingWorker) {
      setShowSwUpdate(true);
    } else setShowSwUpdate(false);
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <GlobalProvider>
      <ConfigProvider
        locale={languageAntd}
        componentSize="large"
        theme={{
          // algorithm: theme.compactAlgorithm,
          hashed: false,
          components: {
            Modal: {
              titleFontSize: 15,
              titleLineHeight: 1.5
            },
            Input: {
              fontSize: 13
            },
            Table: {
              headerBg: "#fff",
              padding: 9,
              borderColor: "rgba(240, 240, 240, 0)"
            },
            Form: {
              verticalLabelPadding: "0px",
              labelHeight: 22,
              controlHeight: 22
            },
            Divider: {
              fontSize: 15,
              padding: 0
            },
            Button: {
              green5: "#42a664",
              green6: "#6ab888",
              green7: "#3fb86d",
              colorPrimaryBg: "#e3f1f9",
              colorPrimaryBgHover: "rgb(27,145,203)",
              colorPrimaryTextHover: "#ffffff"
            },
            Tabs: {
              fontSize: 15,
              horizontalMargin: "0 0 0 0",
              colorBorder: "none"
            }
          },
          token: {
            colorPrimary: "#1b91cb",
            colorSuccess: "#6ab888",
            fontSize: 13,
            colorLink: "#1b91cb",
            colorText: "#4a557d",
            fontFamily: "'Inter', sans-serif",
            colorError: "#ec3e3f"
          }
        }}
      >
        <AuthProvider>
          <AlertProvider>
            {showSwUpdate && (
              <Alert
                message="Update Available"
                description={
                  <div>
                    {t("account.updateFound")}
                    <Button
                      style={{ marginLeft: 16 }}
                      type="primary"
                      size="small"
                      onClick={() => {
                        setShowSwUpdate(false);
                        setShowSwLoading(true);
                        reloadPage();
                      }}
                    >
                      {t("account.updateNow")}
                    </Button>
                  </div>
                }
                type="info"
                onClose={reloadPage}
                showIcon
              />
            )}
            {showSwLoading && (
              <Alert
                message={
                  <>
                    <Spin size="small" /> {t("account.updating")} If this take
                    too long please refresh the page...
                  </>
                }
                type="success"
              />
            )}
            <ErrorBoundary>
              <AppContent />
            </ErrorBoundary>
          </AlertProvider>
        </AuthProvider>
      </ConfigProvider>
    </GlobalProvider>
  );
}

export default App;
// serviceWorkerRegistration.unregister();
// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.ready.then(registration => {
//     registration.unregister();

//     if (caches) {
//       // Service worker cache should be cleared with caches.delete()
//       caches.keys().then(async names => {
//         await Promise.all(names.map(name => caches.delete(name)));
//       });
//     }
//   });
// }

// serviceWorkerRegistration.register({
//   onUpdate: (registration: ServiceWorkerRegistration) => {
//     registration.update().then(() => {
//       console.log(
//         "[SW_updated]: ",
//         registration.waiting?.scriptURL,
//         registration.waiting?.state
//       );
//       registration.waiting?.postMessage("SKIP_WAITING");
//     });
//   }
// });
