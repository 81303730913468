import { Language } from "./General";
import { ImageType } from "./Plan";

export interface PatientUpsert {
  id: number;
  title?: string;
  first_name: string;
  last_name: string;
  email?: string;
  dob?: string;
  dial_code?: number;
  phone?: string;
  phone2?: any;
  send_login_details: boolean;
  patient_consent?: boolean;
  image_type: ImageType;
  clinic_id?: number;
  integration_id?: string;
  pms_id?: string;
  //     popup_form: 0
  // id: 0
  // pps_id: 0
  // pms_id:
  // first_name: Mirko
  // last_name: Lucic2
  // title:
  // email:
  // phone:
  // dob: 12-23-2033
}

export interface PatientTable {
  id: number;
  clinic_id: number;
  // title: string;
  first_name: string;
  last_name: string;
  // email: string;
  // dob: string;
  practitioner_id: number;
  // created: string;
  plans_count: number;
  // image_type: ImageType;
  last_activity: string;
  // send_login_details: boolean;
  member_id: number;
}

export interface Pagination {
  current: string;
}

export interface Filters {
  type: string;
}

export interface PatientTableDTO {
  patients: PatientTable[];
  total_items: number;
  total_pages: number;
  current_page: number;
  search_term: string;
  pagination: Pagination;
  filters: Filters;
}

export interface Patient2 {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
  dob?: string;
  access_code: string;
  email?: string;
  email_cc?: string;
  email_bcc?: string;
  dial_code?: number;
  phone?: string;
  image_type: ImageType;
  send_login_details: boolean;
  created: string;
  last_activity: string;
  member_id: number;
  clinic_id: number;
  deleted: 1 | 0;
  integration_id: string;
}

export interface BodyChart {
  id: number;
  json: string; // Fabric.js JSON string
  clinical_note_id: number;
  created_at: string;
  updated_at: string;
}

export interface ClinicalNote {
  id: number;
  note: string;
  created: string; // ISO 8601 timestamp
  updated: string; // ISO 8601 timestamp
  patient_id: number;
  body_charts: BodyChart[];
  practitioner_id: number;
}
export interface ClinicalNoteTemplate {
  id: number;
  name: string;
  template: string;
  lang: Language;
  created: string; // ISO 8601 timestamp
  updated: string; // ISO 8601 timestamp
  practitioner_id: number;
  sort: number;
  category: "quick" | "rf" | "template";
}
export interface ClinicalNoteTemplateUpsert {
  id: number;
  name: string;
  template: string;
  category: "quick" | "rf" | "template";
  sort: number;
}

// Interface for a list of clinical notes
export interface ClinicalNoteList {
  notes: ClinicalNote[];
}
export interface PlanListProfile {
  id: number;
  name: string;
  practitioner_id?: number;
  patient_id: number;
  created: string;
  lang: Language;
  last_update: string;
  start?: string;
  end?: string;
  finished?: number;
  has_tracking?: number;
  done_precentage?: number;
  done_precentage2?: number;
}
export interface TemplateList {
  id: number;
  name: string;
  desc: string | null;
  practitioner_id?: number;
  patient_id: number;
  created: string;
  last_update: string;
  template_category_id: number;
  template_cats?: number[];
  has_tracking?: number;
}

export interface PatientProfile {
  patient: Patient2;
  plans: PlanListProfile[];
  meetings: any[];
}

export enum PatientHistory {
  PATIENT_CREATED = "p_add",
  PATIENT_UPDATED = "p_update",
  PATIENT_DEL = "p_del",
  EP_CREATED = "e_add",
  EP_DEL = "e_del",
  EP_EMAILED = "e_emailed",
  EP_SMS_SENT = "e_sms_sent",
  EP_WAPP_SENT = "e_whatsapp_sent",
  PATIENT_CLINIKO_IMPORT = "imported_from_cliniko",
  PATIENT_CLINIKO_UPDATE = "updated_from_cliniko",
  PATIENT_NOOKAL_IMPORT = "imported_from_nookal",
  PATIENT_NOOKAL_UPDATE = "updated_from_nookal",
  PATIENT_PLATOMEDICAL_IMPORT = "imported_from_platomedical",
  PATIENT_PLATOMEDICAL_UPDATE = "updated_from_platomedical",
  PATIENT_PMS_UPDATE = "updated_from_pms",
  PATIENT_MERGED = "p_merged"
}

export enum MessageStatus {
  MESSAGE_STATUS_OTHER = "14",
  MESSAGE_STATUS_DELIVERED = "1",
  MESSAGE_STATUS_OPENED = "2",
  MESSAGE_STATUS_BOUNCED = "3",
  MESSAGE_STATUS_COMPLAINT = "4",
  MESSAGE_STATUS_REJECTED = "5"
}
export interface History {
  id: number;
  date: string;
  action: string;
  object: string;
  object2: string;
  message_id: string;
  patient_id: number;
  member_id: number;
  clinic_id: number;
  practitioner_id: number;
  delivery_log: DeliveryLog[];
  delivery_status: string;
  wa_delivery_status: any;
}

export interface DeliveryLog {
  id: number;
  type: number;
  message_id: string;
  sent_using: number;
  sent_to: string;
  status: MessageStatus;
  raw: string;
  reason: string;
  date: string;
}
