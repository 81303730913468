import { UserObject } from "./UserData";

export enum Language {
  arAr = "ar",
  bnBn = "bn",
  deDe = "de",
  elEl = "el",
  enGB = "en-GB",
  enUS = "en-US",
  esES = "es",
  fiFI = "fi",
  frFR = "fr",
  haNg = "ha",
  hiIN = "hi",
  idId = "id",
  itIT = "it",
  jaJa = "ja",
  mrMr = "mr",
  neNe = "ne",
  nlNl = "nl",
  noNo = "no",
  paPa = "pa",
  plPl = "pl",
  ptPT = "pt",
  ruRu = "ru",
  svSv = "sv",
  taTa = "ta",
  teTe = "te",
  trTr = "tr",
  urUr = "ur",
  viVi = "vi",
  zhCn = "zh", // Simplified Chinese
  zhTw = "zh-TW", // Traditional Chinese
  huHu = "hu", // Hungarian
  etEt = "et", // Estonian
  guGu = "gu", // Gujarati
  thTh = "th", // Thai
  koKo = "ko" // Korean
}

export const LanguageNames: Record<Language, string> = {
  [Language.arAr]: "Arabic",
  [Language.bnBn]: "Bengali",
  [Language.zhCn]: "Chinese (Simplified)",
  [Language.zhTw]: "Chinese (Traditional)",
  [Language.nlNl]: "Dutch",
  [Language.enGB]: "English (UK)",
  [Language.enUS]: "English (US)",
  [Language.etEt]: "Estonian",
  [Language.fiFI]: "Finnish",
  [Language.frFR]: "French",
  [Language.deDe]: "German",
  [Language.guGu]: "Gujarati",
  [Language.elEl]: "Greek",
  [Language.haNg]: "Hausa",
  [Language.hiIN]: "Hindi",
  [Language.huHu]: "Hungarian",
  [Language.idId]: "Indonesian",
  [Language.itIT]: "Italian",
  [Language.jaJa]: "Japanese",
  [Language.koKo]: "Korean",
  [Language.mrMr]: "Marathi",
  [Language.neNe]: "Nepali",
  [Language.noNo]: "Norwegian",
  [Language.paPa]: "Punjabi",
  [Language.plPl]: "Polish",
  [Language.ptPT]: "Portuguese",
  [Language.ruRu]: "Russian",
  [Language.esES]: "Spanish",
  [Language.svSv]: "Swedish",
  [Language.taTa]: "Tamil",
  [Language.teTe]: "Telugu",
  [Language.thTh]: "Thai",
  [Language.trTr]: "Turkish",
  [Language.urUr]: "Urdu",
  [Language.viVi]: "Vietnamese"
};

export interface ApiResWithUser {
  status: string;
  message: string;
  successToast: string;
  user?: UserObject;
}
export interface ApiRes {
  status: string;
  message: string;
  successToast: string;
  user?: UserObject;
}

export enum RepsFormat {
  default = "default",
  custom = "custom",
  myTemplate = "myTemplate"
}

export enum ReplyOptions {
  practitioner = "practitioner",
  clinic = "clinic",
  none = "none"
}

export enum ReplyOptionsClinic {
  practitioner = "practitioner",
  clinic = "clinic",
  none = "none",
  practCanChoose = "practCanChoose"
}
