import React, { useState } from "react";
import { Modal, Form, Input, Button, message, Select, Checkbox } from "antd";
import axios from "axios";
import { post } from "../../api/Request";
import { ApiResWithUser } from "../../types/General";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface AddCustomSetRepFieldProps {
  visible: boolean;
  onCancel: () => void;
  onDone: () => void;
}

interface FormValues {
  name: string;
  labelBefore: string;
  inputType: string;
  labelAfter: string;
  enabled_for_all: boolean;
}

const AddCustomSetRepField: React.FC<AddCustomSetRepFieldProps> = ({
  visible,
  onCancel,
  onDone
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();
  const { t } = useTranslation();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    post<ApiResWithUser>("account/addCustomField", form.getFieldsValue())
      .then(r => {
        if (r.data.user) {
          setUser(r.data.user);
          form.resetFields();
          onDone();
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFieldChange = () => {
    form.validateFields(["labelBefore", "labelAfter"]);
  };

  return (
    <Modal
      title={t("plan.reps.addCustomField") as string}
      open={visible}
      onCancel={onCancel}
      okButtonProps={{
        loading: loading
      }}
      onOk={() => {
        form.submit();
      }}
      okText={t("plan.reps.addCustomField")}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          enabled: true,
          inputType: "text"
        }}
      >
        <Form.Item
          name="name"
          label={t("plan.reps.fieldName")}
          rules={[
            { required: true, message: "Please enter the field name" },
            { min: 2, message: "Name must be at least 2 characters" }
          ]}
        >
          <Input placeholder="Enter field name" />
        </Form.Item>

        <div style={{ display: "flex", gap: "16px" }}>
          <Form.Item
            name="labelBefore"
            label={t("plan.reps.labelBefore")}
            style={{ flex: 1 }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    getFieldValue("enabled") &&
                    !value &&
                    !getFieldValue("labelAfter")
                  ) {
                    return Promise.reject(
                      new Error(
                        "Either Label Before or Label After is required when enabled."
                      )
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input onChange={handleFieldChange} />
          </Form.Item>

          <Form.Item name="inputType" label="Input Type" style={{ width: 200 }}>
            <Select onChange={handleFieldChange}>
              <Option value="text">{t("plan.reps.text")}</Option>
              <Option value="number">{t("plan.reps.number")}</Option>
              <Option value="checkbox">{t("plan.reps.checkbox")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="labelAfter"
            label={t("plan.reps.labelAfter")}
            style={{ flex: 1 }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("inputType") === "checkbox" && value) {
                    return Promise.reject(
                      new Error(
                        "Label After is not allowed for Checkbox input type."
                      )
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input onChange={handleFieldChange} />
          </Form.Item>
        </div>
        <Form.Item name="enabled_for_all" valuePropName="checked">
          <Checkbox onChange={handleFieldChange}>
            {t("plan.reps.enableForAll")}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCustomSetRepField;
