import React, { useEffect, useState } from "react";
import { Form, Input, Button, Alert, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { post } from "../../../api/Request";
import ConfirmButton from "../../../components/helper/ConfirmButton";

const { Text, Paragraph } = Typography;
const Webhook: React.FC = () => {
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingAk, setLoadingAk] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [secret, setSecret] = useState("");
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        webhook_url: user.settings.webhook_url ?? ""
      });
    }
  }, [user, form]);

  const handleDelete = () => {
    setLoadingDelete(true);
    post("integrations/deleteWebhook")
      .then(r => {
        if (r.data.user) {
          setUser(r.data.user);
          form.setFieldsValue({ webhook_url: "" });
        }
      })
      .catch(_ => {})
      .finally(() => setLoadingDelete(false));
  };

  return (
    <>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={v => {
          // console.log(v, "upsertkey");
          setLoadingAk(true);
          post("integrations/upsertWebhook", v)
            .then(r => {
              if (r.data.user) setUser(r.data.user);
              if (r.data.webhook_secret) {
                setSecret(r.data.webhook_secret);
              }
            })
            .catch(_ => {})
            .finally(() => setLoadingAk(false));
        }}
        autoComplete="on"
      >
        <Form.Item label={t("integrations.webhookUrl")} name="webhook_url">
          <Input type="url" disabled={!!user?.settings.webhook_url} />
        </Form.Item>

        <Form.Item>
          <Space align="center" style={{ width: "100%", gap: "8px" }}>
            {user?.settings.webhook_url && (
              <ConfirmButton
                btnText={t("delete")}
                message={t("confirmations.deleteWebhook")}
                btnProps={{
                  loading: loadingDelete,
                  variant: "filled",
                  color: "red"
                }}
                onConfirm={() => {
                  handleDelete();
                }}
              />
            )}
            <Button loading={loadingAk} type="primary" htmlType="submit">
              {user?.settings.webhook_url
                ? t("integrations.webhookUpdateSecret")
                : t("save")}
            </Button>
            <Button
              type="dashed"
              href="https://www.rehabmypatient.com/api#webhook-integration-documentation"
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              Read Webhook Docs
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {secret && (
        <div style={{ marginTop: 24 }}>
          <Alert
            message={t("integrations.webhookSecretTitle")}
            description={
              <Space direction="vertical" size="small">
                <Paragraph>
                  {t("integrations.webhookSecretDescription")}
                </Paragraph>
                <Space>
                  <Text copyable>{secret}</Text>
                </Space>
              </Space>
            }
            type="warning"
            showIcon
          />
        </div>
      )}
    </>
  );
};

export default Webhook;
