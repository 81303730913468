import { useContext } from "react";
import { createContext } from "react";
import { UserObject } from "../types/UserData";

import React, { useEffect, useState } from "react";
// import http from "../api/Request";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";
import { get, post } from "../api/Request";
import { Spin } from "antd";
interface AuthContextType {
  user: UserObject | null;
  authLoading: boolean;
  setUser: (user: UserObject) => void;
  logout: () => void;
  refreshSession: () => void;
}

const authContextDefaultValues: AuthContextType = {
  user: null,
  authLoading: true,
  setUser: () => {},
  refreshSession: () => {},
  logout: () => {}
};

export const AuthContext = createContext<AuthContextType>(
  authContextDefaultValues
);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const navigate = useNavigate();
  const { setLanguage, language } = useGlobalContext();

  const [user, setUserData] = useState<UserObject | null>(null);
  const [authLoading, setAuthLoading] = useState<boolean>(true);

  useEffect(() => {
    refreshSession();
    //if in url msg=logout, logout
  }, []);

  useEffect(() => {
    if (user !== null) {
      if (user.id > 0) {
      } else {
        setUser(null);
        // localStorage.removeItem("usr");
      }
    }
    if (
      user !== null &&
      user.id !== undefined &&
      user.practitioner.lang !== language
    )
      setLanguage(user.practitioner.lang);
  }, [user, language]);

  const setUser = (user: UserObject | null) => {
    if (user !== null) {
      if (user.id > 0) {
        // localStorage.setItem("usr", JSON.stringify(user));
        setUserData(user);
        setLanguage(user.practitioner.lang);
      }
    } else {
      //probably error from server
      setUserData(null);
      // localStorage.removeItem("usr");
      // navigate("/login", { replace: true });
    }
  };

  const logout = () => {
    setAuthLoading(true);
    get("auth/logout/")
      .then(_ => {
        setUser(null);
        navigate("/login", { replace: true });
      })
      .finally(() => {
        setAuthLoading(false);
      });
  };
  const refreshSession = () => {
    console.log("refreshing session");
    post<UserObject>("auth/refresh/")
      .then(r => {
        setUser(r.data);
      })
      .catch(_ => {
        setUser(null);
        navigate("/login", { replace: true });
      })
      .finally(() => {
        // setTimeout(() => {
        setAuthLoading(false);
        // }, 2000);
      });
  };

  const value = {
    user,
    authLoading,
    setUser,
    refreshSession,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {value.authLoading ? <Spin spinning={true} fullscreen></Spin> : children}
    </AuthContext.Provider>
  );
};
